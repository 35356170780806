/** 
 *   分类
 */
import { get } from '@/untils/js/axios.js'

// 获取一级分类
export const getClassifyList = params => get('/type/list-one', params)

// 获取二级分类
export const getClassifyLists = id => get(`/type/list-two/${id}`)

// 获取独立板块下的商品
export const getClassifyGoods = params => get('/type/goods-list', params)
