<template>
  <section class="sub-classify m-scroll" ref="scroll" @scroll="handleScroll($event)">
    <!-- 头部导航 -->
    <HeaderBar :title="title">
      <router-link to="/zh/cart" slot="right" class="solt-right">
        <img src="@/assets/index/icon_shop_b@3x.png"  alt="cart" class="right-img">
      </router-link>
    </HeaderBar>

    <div class="goods-sort-bar">
      <div class="sort-item" :class="{'sort-active' : sortType == 1}" @click="changeSortType(1)"><span>综合</span></div>
      <div class="sort-item" :class="{'sort-active' : sortType == 3}" @click="changeSortType(3)"><span>销量</span></div>
      <div class="sort-item" :class="{'sort-active' : sortType == 2}" @click="changeSortType(2)"><span>新品</span></div>
      <div class="sort-item" :class="{'sort-active active-up' : sortType == 4, 'sort-active active-down': sortType == 5}" @click="changeSortType(4)">
        <span>价格</span>
      </div>
    </div>

    <goods-columns @cartClick="handleCart" :data="goodsList" :columns="2" style="margin-top:16px"></goods-columns>
    <p class="search-no" v-if='noMore'>暂无更多商品</p>  
    
    <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>

    <!-- 选择SKU 加入购物车 -->
    <m-sku title="加入购物车" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

    <!-- 购物车浮窗 -->
    <transition name="van-fade">
        <CartFlex v-if="start" />
    </transition>
  </section>
</template>

<script>
import MSku         from '@/components/zh/m-sku.vue'
import GoodsColumns from '@/components/zh/goods-columns.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'
import CartFlex     from '@/components/zh/cart-flex.vue'
import HeaderBar    from '@/components/zh/m-header.vue'

import { getClassifyGoods } from '@/api/zh/classify.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
  name:'Index',
  mixins: [ MixinScroll ],
  components:{ GoodsColumns, MSku, MAnimate, CartFlex, HeaderBar },
  data(){
    return {
      recomnd   : 4,
      loading   : false,
      skuModal  : false,
      tabTop    : 44,
      dotAnimate: false,
      start     : false,
      start_dom : null,
      cartNum   : 0,
      scrollTop : false,
      title     : '',
      goodsList: [],
      page: 1,
      noMore: false,
      sortType: 1,
      goodsId: '',
      classifyId: 0
    }
  },
  methods:{
    // 内容滑动事件
    handleScroll(e){
      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock && !this.noMore){
          e.preventDefault()
          e.stopPropagation()
          this.loading = true
          this.page++
          this.getSubClassifyGoodsHandle()
        }
      }
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e 
    },
     // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
        this.start  = true
        this.dotAnimate = true 
        this.cartNum ++

        let timer1 = setTimeout(()=> {
          this.dotAnimate = false
          clearTimeout(timer1)
        },400)

        clearTimeout(timer)
      },300)

      
    },
    // 加入购物车动画结束
    handleCartEnd(){
      this.start    = false
    },
    getSubClassifyGoodsHandle() {
      // 获取分类下的商品
      this.islock   = true
      this.loading  = true
      this.$store.commit('load')
      getClassifyGoods({type_id: this.$route.query.id, sort: this.sortType, page: this.page}).then(res => {
        this.loading  = false
        this.islock   = false
        if(res) {
          this.goodsList = this.goodsList.concat(res.data.data)
        }else{
          this.noMore = true
        }
        this.$store.commit('unload')
      })
    },
    changeSortType(sort) {
        // 修改排序方法
        if(this.sortType == 4 && sort == 4) {
            sort = 5
        }
        this.sortType = sort
        this.goodsList = []
        this.page = 1
        this.noMore = false

        this.getSubClassifyGoodsHandle()
    },
  },
  mounted() {
    this.goodsList = []
    this.getSubClassifyGoodsHandle()
    this.title = this.$route.query.title
    this.classifyId = this.$route.query.id
  },
  activated() {
    if(this.classifyId != this.$route.query.id) {
      // 重新获取数据
      this.goodsList = []
      this.getSubClassifyGoodsHandle()
      this.title = this.$route.query.title

      this.classifyId = this.$route.query.id
    }
  },
  watch: {
      title(val) {
          if(val) {
            this.title = val
          }
      }
  }
}
</script>

<style lang="less" scoped>
.sub-classify {
  width: 100%;
  height: 100%;
  padding-top: 44px;
  background-color: #F5F9FF;
  overflow-x: hidden;
  overflow-y: auto;
  .goods-sort-bar{width:100%;height:44px;display: flex;margin-bottom:8px;
    .sort-item{
      text-align: center;
      flex: 1;height:100%;font-size:16px;line-height: 44px;
      
      font-weight:400;
      color:rgba(51,51,51,1);
    }
    .sort-active{position: relative;color:#fff;
      &::before{
        content: '';
        width:65px;
        height:29px;
        background:linear-gradient(90deg,rgba(45,119,224,1) 0%,rgba(36,95,179,1) 100%);
        border-radius:15px;
        position: absolute;top:50%;left:50%;z-index: 0;transform: translate(-50%, -50%);
      }
      >span{position: relative;z-index: 2;}
    }
    .active-down{padding-right:10px;
      &::after{content: '';position: absolute;top:20px;right:26px;width:0;height:0;border:4px solid transparent;border-top-color:#fff;
      display: block;}
    }
    .active-up{padding-right:10px;
      &::after{content: '';position: absolute;top:15px;right:26px;width:0;height:0;border:4px solid transparent;border-bottom-color:#fff;
      display: block;}
    }
  }
}
.right-img {
  width:22px;
  height:22px;
  margin:6px;
}
.search-no {
  line-height:80px;
  text-align:center;
  color:#888;
  font-size:14px;
}
</style>